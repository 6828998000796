import {RoleHolderStyledComponentProps} from '../../types';
import styled from 'styled-components';
import apptheme from '../../styles/theme';

export const cellGap = `1em`;
export const cellWidth = `200px`;

const mainTealColor = apptheme.mainTealColor;
const blackFontColor = apptheme.blackFontColor;
const greyishBrown = apptheme.greyishBrown;

interface RoleHolderStyledComponentPropsWithSwap extends RoleHolderStyledComponentProps {
  isSelecting: boolean;
  shouldGreyOut: boolean;
}

interface RoleHolderProp {
  iconLength:any;
}

interface DataHolderColorProp {
  dataholderColor?:any;
}

/** grid areas */

export const DayOfWeek = styled.div`
  background-color: white;
  display: flex;
  top: 0;
  align-items: flex-end;
  justify-content: center;
  padding-left: 2px;
  z-index: 2;
  padding-bottom: 5px;
  border-bottom: 1px solid lightgray;
`;

export const DateGrid = styled.div`
  background-color: lightgray;
  border: 1px solid lightgray;
  border-top: unset;
`;

export const ActionCalendar = styled.div`
  width: 100%;
  min-width: min-content;
  ${DayOfWeek}, ${DateGrid} {
    display: grid;
    grid-gap: 1px;
    grid-template-columns: repeat(7, 1fr);
  }
`;

/** general */

export const Weekday = styled.div`
  line-height: 1.5;
  padding-top: 2px;
  color: ${(props) => props.theme.greyishBrown};
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const DateHolder = styled.div<DataHolderColorProp>`
  min-width: 165px;
  min-height: 185px;
  padding: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => ((props.dataholderColor === undefined ?'white': props.dataholderColor))};
`;

export const DateIndicator = styled.div`
  align-self: flex-start;
  font-size: 14px;
  width: 100%;
  margin-bottom: 4px;
`;

/** Assignee Details */

export const AssigneeWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const RoleHolderTitle = styled.div`
  padding-bottom: 6px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  width: 100%;
`;

export const RoleHolderTitleTime = styled.div`
  letter-spacing: normal;
  padding-left: 2px;
  min-width: max-content;
`;

export const TimeFirstRoleHolderTitleName = styled.div<RoleHolderProp>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${(props) => ((props.iconLength === undefined ?'120': (props.iconLength < 2  ? '95' : '79')))}px;
  height: 18px;
  left: 46px;
  top: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
`;

export const RoleHolderTitleName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
`;

export const RoleHolderWrapper = styled.div<RoleHolderStyledComponentPropsWithSwap>`
  padding: 4px 6px;
  position: relative;
  user-select: none;
  cursor: pointer;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${(props) => (props.shouldGreyOut ? 'lightgrey' : 'inherit')};
  border-left: 5px solid ${(props) => (props.shouldGreyOut ? 'rgba(182, 186, 219, 0.15)' : props.assignedColor)};
  background-color: ${(props) =>
    props.shouldGreyOut ? 'rgba(182, 186, 219, 0.08)' : props.assignedColorBackground || 'lightgrey'};

  &:hover {
    background-color: lightgrey !important;
    border-left: 5px solid black !important;
    color: ${(props) => props.theme.blackFontColor};
  }
`;

export const RoleHolderWrapperForTimeFirst = styled.div<RoleHolderStyledComponentPropsWithSwap>`
  padding: 4px 2px;
  position: relative;
  user-select: none;
  cursor: pointer;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${(props) => (props.shouldGreyOut ? 'lightgrey' : 'inherit')};
  border-left: 5px solid ${(props) => (props.shouldGreyOut ? 'rgba(182, 186, 219, 0.15)' : props.assignedColor)};
  background-color: ${(props) =>
    props.shouldGreyOut ? 'rgba(182, 186, 219, 0.08)' : props.assignedColorBackground || 'lightgrey'};

  &:hover {
    background-color: lightgrey !important;
    border-left: 5px solid black !important;
    color: ${(props) => props.theme.blackFontColor};
  }
`;

export const RoleHolderDetail = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-break: anywhere;
  overflow-wrap: anywhere;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RoleHolderDetailForTimeFirst = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${greyishBrown};
  line-break: anywhere;
  overflow-wrap: anywhere;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RoleIconHolder = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
`;

export const TimeFirstRoleIconHolder = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-wrap: nowrap;
`;

export const SwapShiftIconHolder = styled.div`
  position: relative;
  bottom: 0;
  right: -2px;
  height: 1em;
  width: 20px;
  padding: 0 4px;
  background-color: ${(props) => props.theme.priorityYellow};
  border-radius: 2px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const MarketplaceIconHolder = styled.div`
  display: flex;
  height: 100%;
  width: 28px;
  justify-content: flex-end;
  svg {
    margin-left: 2px;
    &:nth-child(2) {
      margin-left: 4px;
    }
  }
`;

export const RoleGridWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 5.27px;
  right: 6.5px;
  margin-left: 7.35;
`;

export const AssigneeTimeWrapper = styled.div`
  width: 32px;
  height: 33px;
  top: calc(50% - 33px / 2 + 0.5px);
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${blackFontColor};
  margin-right: 12px;
`;

export const RoleHolderHoliday = styled.div`
  color: ${(props) => props.theme.blackFontColor};
  width: ${cellWidth};
`;

export const RoleHolderWrapperHoliday = styled.div<RoleHolderStyledComponentProps>`
  height: 18px;
  left: 9px;
  top: 25px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: ${mainTealColor};
  background-color: ${(props) => props.assignedColorBackground};
  border-left: 5px solid ${(props) => props.assignedColor};
  cursor: pointer;
  margin-bottom: 5px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 161px;
}
`;

export const RoleAndIconWrapper = styled.div`
  width:74%;
`;