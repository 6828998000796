import * as React from 'react';

export default (props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.16868 3.5625H11.6249C11.6937 3.5625 11.7499 3.61875 11.7499 3.6875V4.625C11.7499 4.69375 11.6937 4.75 11.6249 4.75H0.749927C0.332739 4.75 0.0999265 4.26875 0.357739 3.94062L2.99524 0.595312C3.04368 0.534375 3.11555 0.5 3.19211 0.5H4.32493C4.42961 0.5 4.48743 0.621875 4.42336 0.703125L2.16868 3.5625ZM0.374927 7.25H11.2484C11.6671 7.25 11.8999 7.73125 11.6421 8.05937L9.00461 11.4047C8.95618 11.4656 8.8843 11.5 8.80774 11.5H7.67493C7.57024 11.5 7.51243 11.3781 7.57649 11.2969L9.83118 8.4375H0.374927C0.306177 8.4375 0.249927 8.38125 0.249927 8.3125V7.375C0.249927 7.30625 0.306177 7.25 0.374927 7.25Z"
      fill="black"
    />
  </svg>
);
