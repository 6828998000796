import {CalenderView} from './CalenderView';
import {CalenderViewDataSourceProtocol} from './CalenderViewDataSourceProtocol';

export class CalenderViewDelegateProtocol {
  delegateCalenderView = null;
  constructor(startDateISOstring: string,holidays?: any,isHolidayMark?: boolean) {
    CalenderViewDataSourceProtocol.isHolidayMark = isHolidayMark;
    new CalenderViewDataSourceProtocol(startDateISOstring);
    CalenderViewDataSourceProtocol.holidays = holidays;
    this.delegateCalenderView = new CalenderView();
  }

  renderShift() {
    this.renderData();
    return this.delegateCalenderView.renderTableData();
  }

  addDatatoCalender(index: number, data: any) {
    this.delegateCalenderView.addDatatoCalenderItem(index, data);
  }

  renderData() {}
}
