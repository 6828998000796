const watermelon = '#ff3e55';
const errorRed = '#F44336';
const greyishBrown = '#4a4a4a';
const black = '#2b2b2b';
const redPink = '#ef3954';
const priorityYellow = '#ffc705';
const statRed = '#CB3E00';
const borderColor = '#d1d1d1';
const lightGrey = '#f2f2f2';
const chatBlue = '#3569e4';
const chatTeal = '#00859A';
const chatGrey = '#e6e7ec';
const warmGrey = '#9b9b9b';
const chatIconGrey = '#cac9c9';
const chatTextTitle = '#262626';
const slightlyGrey = '#c7c7cd';
const placeholder = '#adadad';
const appleGreen = '#7ed321';
const frogGreen = '#3bcc0b';
const androidGreen = '#009689';
const blackFontColor = '#222222';
const messageActionIconGrey = '#8B8B8B';
const labelGrey = '#969696';
const disabled = '#fcd6db';
const disabledLight = '#DCDCDC';
const borderBottomLight = '#d8d8d8';
const popperBackground = 'rgba(38, 39, 40, 0.9)';
const primary = '#00859A';
const ivory = '#fff4cd';
const lightYellow = '#FFEE33';
const messageBlack = '#222';
const messageWhite = '#fff';
const mainTealColor = '#00859A';

export default {
  main: watermelon,
  secondary: redPink,
  text: greyishBrown,
  labelGrey,
  ivory,
  disabled,
  disabledLight,
  primary,
  warmGrey,
  borderColor,
  watermelon,
  greyishBrown,
  redPink,
  black,
  priorityYellow,
  lightYellow,
  lightGrey,
  chatBlue,
  chatTeal,
  chatGrey,
  iconGrey: warmGrey,
  chatTextTitle,
  chatIconGrey,
  slightlyGrey,
  placeholder,
  appleGreen,
  androidGreen,
  frogGreen,
  errorRed,
  blackFontColor,
  messageActionIconGrey,
  borderBottomLight,
  popperBackground,
  statRed,
  messageBlack,
  messageWhite,
  mainTealColor,
};
