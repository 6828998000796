import React from 'react';
import hexToRgb from '../../utils/scheduling/hexToRgba';
import {
  RoleHolderWrapperForTimeFirst,
  RoleHolderDetail,
  TimeFirstRoleHolderTitleName,
  TimeFirstRoleIconHolder,
  MarketplaceIconHolder,
  SwapShiftIconHolder,
  RoleGridWrapper,
  AssigneeTimeWrapper,
  IconWrapper,
  RoleHolderDetailForTimeFirst,
  RoleAndIconWrapper,
} from './ActionCalendarGridSystem';
import isEndTimeMinutesBeforeStartTime from '../../utils/scheduling/isEndTimeMinutesBeforeStartTime';
import {ClickAwayListener, Fade, MenuItem, MenuList, Paper, Popper} from '@material-ui/core';
import {StyledPopper} from './SharedPopperStyles';
import {ScheduleLayoutRouterParams, Assignee} from '../../types';
import SwapShiftIcon from '../../svgs/SwapShiftIcon';
import {GIVEAWAYSHIFTVIEW, SWAPSCHEDULINGVIEW} from '../../constants/scheduler';
import GiveAwayShiftIcon from '../../svgs/GiveAwayShiftIcon';
import {ThemeProvider} from 'styled-components';
import theme from '../../styles/theme';

interface Props {
  assignee: Assignee;
  roleName: string;
  userToColorCodes: {
    [userId: string]: string;
  };
  onCellClick: any;
  menuItem: {
    name: JSX.Element;
    clickHandler: any;
  }[];
  anchorEl: HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement>>;
  isSelf?: boolean;
  onMouseEnter?: any;
  onMouseLeave?: any;
  params?: ScheduleLayoutRouterParams;
  showOfferTypes?: boolean;
  isSelecting?: boolean;
  shouldGreyOut?: boolean;
  shiftIcon?: JSX.Element;
}
export function TimeFirstVariant({
  assignee,
  roleName,
  userToColorCodes,
  onCellClick,
  menuItem,
  anchorEl,
  setAnchorEl,
  isSelf = false,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  params = null,
  showOfferTypes,
  isSelecting = false,
  shouldGreyOut = false,
  shiftIcon = <></>,
}: Props) {
  const shiftIconLength = shiftIcon.props?.children?.filter(item => {
    return(typeof(item) === typeof({}))}).length;

  function modalHandleClick(callBackFunction: any) {
    setAnchorEl(null);
    callBackFunction();
  }
  
  function handleClickAway(e: React.MouseEvent<Document, MouseEvent>) {
    // @ts-ignore
    if (anchorEl && anchorEl.contains(e.target)) return;
    setAnchorEl(null);
  }

  return (
    <div key={`shift-${assignee?.shiftId}`}>
      <ThemeProvider theme={theme}>
      <RoleHolderWrapperForTimeFirst
        shouldGreyOut={Boolean(shouldGreyOut)}
        isSelecting={isSelecting}
        assignedColorBackground={hexToRgb(userToColorCodes[assignee.userId], 0.1)}
        assignedColor={userToColorCodes[assignee.userId]}
        className={`${assignee?.shiftId}`}
        onClick={onCellClick}
        title={assignee.userFullName}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <RoleGridWrapper>
          <AssigneeTimeWrapper>
            {`${assignee.startTime.format('HH:mm')} ${assignee.endTime.format('HH:mm')}`}
            {assignee.endTime.format('DD') !== assignee.startTime.format('DD') && <sup>+1</sup>}
          </AssigneeTimeWrapper>
          <RoleAndIconWrapper>
            <RoleGridWrapper>
              <div>
                <TimeFirstRoleHolderTitleName iconLength={shiftIconLength}>{isSelf ? 'ME' : assignee.userFullName}</TimeFirstRoleHolderTitleName>
              </div>
              <IconWrapper>{shiftIcon}</IconWrapper>
            </RoleGridWrapper>
            <TimeFirstRoleIconHolder>
              <RoleHolderDetailForTimeFirst>{roleName}</RoleHolderDetailForTimeFirst>
              {showOfferTypes && assignee.offerTypes && (
                <MarketplaceIconHolder>
                  {assignee.offerTypes.includes(SWAPSCHEDULINGVIEW) && <SwapShiftIcon />}
                  {assignee.offerTypes.includes(GIVEAWAYSHIFTVIEW) && <GiveAwayShiftIcon />}
                </MarketplaceIconHolder>
              )}
              {isSelecting && params?.actionType === SWAPSCHEDULINGVIEW && (
                <SwapShiftIconHolder>
                  <SwapShiftIcon />
                </SwapShiftIconHolder>
              )}
            </TimeFirstRoleIconHolder>
          </RoleAndIconWrapper>
        </RoleGridWrapper>
      </RoleHolderWrapperForTimeFirst>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        className="popper__container"
        placement="bottom-end"
        transition
        disablePortal
      >
        <StyledPopper open={Boolean(anchorEl)} anchorEl={anchorEl} placement={'bottom-start'} transition>
          {({TransitionProps}) => (
            <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper className="popper__content__wrapper">
                  <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
                    <MenuList>
                      {menuItem.map((item, index) => {
                        return (
                          <MenuItem disableTouchRipple onClick={() => modalHandleClick(item.clickHandler)} key={index}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </StyledPopper>
      </Popper>
      </ThemeProvider>
    </div>
  );
}
